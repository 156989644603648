// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import { useState, useEffect } from 'react';
import Matches from './Matches';
import SearchBar from './SearchBar';
import Odds from './Odds';
import Coffee from './coffee.js';

import * as oddslib from './oddslib.js';

const MatchesWithSearch = () => {
  const [matches, setMatches] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [keyword, setKeyword] = useState('');
  const [odds, setOdds] = useState([]);
  const [oddsFormat, setOddsFormat] = useState("prob");

  const fetchMatches = async () => {
    try {
      const data = JSON.parse(await (await fetch('https://api.getodds.win/', { method: 'PUT', headers: {"Content-Type": "application/json"}, body: '{"matchesInfo": "football"}'})).json());
      const sortedMatches = data.matches.filter(match => {return Date.parse(match.matchDate) > (new Date())}).sort((a, b) => (a.matchDate > b.matchDate ? 1 : -1));
      let sortedMatchesString = JSON.stringify(sortedMatches);
      sortedMatchesString = sortedMatchesString
        .replace(/Chinese League 1/g, '中甲 Chinese League 1')
        .replace(/Shenyang Urban FC/g, '沈阳城市 Shenyang Urban FC')
        .replace(/Jinan Xingzhou/g, '济南兴州 Jinan Xingzhou')
        .replace(/Wuxi Wugou/g, '无锡吴鈎 Wuxi Wugou')
        .replace(/Yanbian Longding/g, '延边龙鼎 Yanbian Longding')
        .replace(/Sichuan Jiuniu FC/g, '四川九牛 Sichuan Jiuniu FC')
        .replace(/Dantong Hantong/g, '丹东腾跃 Dantong Hantong')
        .replace(/Dongguan Guanlian/g, '东莞莞联 Dongguan Guanlian')
        .replace(/Jiangxi Liansheng/g, '江西联盛 Jiangxi Liansheng')
        .replace(/Qingdao Youth Island/g, '青岛中创 Qingdao Youth Island')
        .replace(/Heilongjiang Lava Spring/g, '黑龙江 Heilongjiang Lava Spring')
        .replace(/Guangxi Baoyun/g, '广西宝韵 Guangxi Baoyun')
        .replace(/Shanghai Jiading/g, '上海嘉定 Shanghai Jiading')
        .replace(/Hebei KungFu/g, '河北功夫 Hebei KungFu')
        .replace(/Guangzhou FC/g, '广州队 Guangzhou FC')
        .replace(/Suzhou Dongwu/g, '苏州东吴 Suzhou Dongwu')
        .replace(/Nanjing Fengfan/g, '南京风帆 Nanjing Fengfan')
        .replace(/Chinese Super League/g, '中超 Chinese Super League')
        .replace(/Shanghai Port FC/g, '上海海港 Shanghai Port FC')
        .replace(/Changchun Yatai/g, '长春亚泰 Changchun Yatai')
        .replace(/Dalian Yifang/g, '大连人 Dalian Yifang')
        .replace(/Nantong Zhiyun F.C/g, '南通支云 Nantong Zhiyun F.C')
        .replace(/Zhejiang Greentown/g, '浙江队 Zhejiang Greentown')
        .replace(/Meizhou Hakka/g, '梅州客家 Meizhou Hakka')
        .replace(/Wuhan Three Towns/g, '武汉三镇 Wuhan Three Towns')
        .replace(/Beijing Guoan/g, '北京国安 Beijing Guoan')
        .replace(/Henan Songshan Longmen/g, '河南队 Henan Songshan Longmen')
        .replace(/Chengdu Rongcheng/g, '成都蓉城 Chengdu Rongcheng')
        .replace(/Shanghai Shenhua/g, '上海申花 Shanghai Shenhua')
        .replace(/Shandong Taishan/g, '山东泰山 Shandong Taishan')
        .replace(/Shenzhen FC/g, '深圳队 Shenzhen FC')
        .replace(/Qingdao Jonoon/g, '青岛海牛 Qingdao Jonoon')
        .replace(/Qingdao Red Lions/g, '青岛红狮 Qingdao Red Lions')
        .replace(/Chongqing/g, '重庆 Chongqing')
        .replace(/Yunnan Yukun/g, '云南玉昆 Yunnan Yukun')
        .replace(/Tianjin Jinmen Tiger FC/g, '天津津门虎 Tianjin Jinmen Tiger FC')
        .replace(/Dalian Yingbo/g, '大连英博 Dalian Yingbo')
        .replace(/Cangzhou Mighty Lions/g, '沧州雄狮 Cangzhou Mighty Lions');
      const sortedMatchesCN = JSON.parse(sortedMatchesString);
      setAllMatches(sortedMatchesCN);
      setMatches(null);
      setOdds(null);
      setError(null);
    } catch (err) {
      setError(err.message);
      setMatches(null);
    } finally {
      setLoading(false);
    }
  };

  const updateKeyword = (keyword) => {
    setError(null);
    setOdds(null);
    const filtered = allMatches.filter(match => {
     return `${match.host} vs ${match.guest} (${match.competition}) at ${new Date(match.matchDate)}`.toLowerCase().includes(keyword.toLowerCase());
    })
    setKeyword(keyword);
    if (keyword === ''){
      setMatches(null);
    }else{
      setMatches(filtered);
    }
  }

  useEffect(() => {
    fetchMatches();
  }, []);

  const handleKeyDown = async function(e) {
    if (e.key === "Enter" ){
      const keyword = e.target.value;
      const filtered = allMatches.filter(match => {
        return `${match.host} vs ${match.guest} (${match.competition}) at ${new Date(match.matchDate)}`.toLowerCase().includes(keyword.toLowerCase());
      })
      if (filtered.length > 0){
        const matchID = filtered[0];
        updateOdds(matchID);
      }else{
        setError('There is no match for your search. Please try again.');
        ;
      }
    }
  }

  const updateOdds = async function(matchID) {
    updateKeyword('');
    const matchDateMilSec = Date.parse(matchID.matchID.slice(-24));
    const nowMilSec = (new Date()).getTime();
    if (nowMilSec >= matchDateMilSec){
      setError('This match has already started or finished. Please select an upcoming match to get odds.');
      const sortedMatches=allMatches.filter(match => {return Date.parse(match.matchDate) > (new Date())}).sort((a, b) => (a.matchDate > b.matchDate ? 1 : -1));
      setAllMatches(sortedMatches);
      return;
    }
    try{
      let resp = await (await fetch("https://api.getodds.win/", { method: 'PUT', headers: {"Content-Type": "application/json"}, body: JSON.stringify(matchID) })).json();
      const selectedMatch = allMatches.filter(match => {return match.matchID === matchID.matchID});
      resp.host = selectedMatch[0].host;
      resp.guest = selectedMatch[0].guest;
      if (selectedMatch[0].competition == 'USA - Presidential Election 2024'){
        resp.draw = 'Other';
      }else{
        resp.draw = 'Draw';
      }
      resp.matchDate = selectedMatch[0].matchDate;
      resp.competition = selectedMatch[0].competition;
      resp.oddsWin = oddslib.from('impliedProbability', parseFloat(resp.probWin));
      resp.oddsDraw = oddslib.from('impliedProbability', parseFloat(resp.probDraw));
      resp.oddsLose = oddslib.from('impliedProbability', parseFloat(resp.probLose));
      setOdds(resp);
    } catch (err) {
      setError('Sorry, the server is busy. Please try again in a few minutes.');
      const sortedMatches=allMatches.filter(match => {return Date.parse(match.matchDate) > (new Date())}).sort((a, b) => (a.matchDate > b.matchDate ? 1 : -1));
      setAllMatches(sortedMatches);
    }
  }
    
  return (
    <> { /* React fragment */}
    <div className="container w-80">
      <div id="group">
      <div className="container p-3">
          <div className="btn-group w-100">
              <button className="btn btn-link link-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#target1" aria-expanded="false">
                  FAQ
              </button>
              <button className="btn btn-link link-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#target2" aria-expanded="false">
                  Disclaimer
              </button>
              <button className="btn btn-link link-secondary" type="button" data-bs-toggle="collapse" data-bs-target="#target3" aria-expanded="false">
                  Contact
              </button>
          </div>
        <div className="panel w-100">
            <div className="collapse" id="target1" data-bs-parent="#group">
                <div className="card card-body bg-light text-start fs-6">
                    <ul className="list-unstyled">
                      <br></br>
                      <li className="fst-italic">Q: How do you get the odds on a football match?</li>
                      <li>A: The odds are calculated from the ongoing sports market consensus and statistics that are publicly available on the internet.</li>
                      <br></br>
                      <li className="fst-italic">Q: What is the coverage on football competitions?</li>
                      <li>A: As for now, <a href="https://getodds.win">GetOdds</a> covers English Premier League, Spanish La Liga, Italian Serie A, French Ligue 1, German Bundesliga, major UEFA/FIFA competitions and many more. If you would like to see your favourite game to be covered, just send me an <a href="mailto:contact@getodds.win">email</a>.</li>
                      <br></br>
                      <li className="fst-italic">Q: Why don't the odds refresh when I search the same match again?</li>
                      <li>A: As a free website, <a href="https://getodds.win">GetOdds</a> is running on very limited resources to keep cost down, and therefore I need to balance the computational load on the server by caching results from earlier requests if they were calculated not long ago. That said, I will try my best to keep the odds accurate and up to date.</li>
                      <br></br>
                    </ul>
                </div>
            </div>
        </div>
        <div className="panel w-100">
            <div className="collapse" id="target2" data-bs-parent="#group">
                <div className="card card-body bg-light fs-6 text-start">
                  <ul className="list-unstyled">
                    <br></br>
                    <li>All data and information on this website is provided 'as is' for informational purposes only. <a href="https://getodds.win">GetOdds</a> cannot guarantee the accuracy of the odds displayed, and shall not be liable for any consequences relating to your use of the information provided herein.</li>
                    <br></br>
                  </ul>
                </div>
            </div>
        </div>
        <div className="panel w-100">
            <div className="collapse" id="target3" data-bs-parent="#group">
                <div className="card card-body bg-light fs-6 text-start">
                  <ul className="list-unstyled">
                    <br></br>
                    <li>For questions, suggestions or bug reports, feel free to drop me an <a href="mailto:contact@getodds.win">email</a>.</li>
                    <br></br>
                    <li>If you find this website useful, you can show your support by buying me a coffee&nbsp;<span className="text-danger fs-4">&#9829;</span>&nbsp;&nbsp;&nbsp;<Coffee/></li>
                    <br></br>
                  </ul>
                </div>
            </div>
        </div>
      </div>
      </div>
      <div className="container text-center centered p-2">
          <h1>
            <span className="text-primary">G</span>
            <span className="text-warning">e</span>
            <span className="text-danger">t</span>
            &nbsp;
            <span className="text-primary">O</span>
            <span className="text-success">d</span>
            <span className="text-primary">d</span>
            <span className="text-danger">s</span>
            &nbsp;
            <span className="fw-light fs-3 text-secondary">on Any Football Match</span>
          </h1>
      </div>
      <div className="container fs-6 text-secondary pb-2">Display odds as:&nbsp;</div>
      <div className="container pb-2">
      <input type="radio" className="btn-check btn-sm" name="odds-format" id="odds-prob" autoComplete="off" onClick={() => setOddsFormat("prob")} defaultChecked/>
      <label className="fw-light btn btn-outline-secondary btn-sm" htmlFor="odds-prob">Probability</label>
      &nbsp;
      <input type="radio" className="btn-check btn-sm" name="odds-format" id="odds-fractional" autoComplete="off" onClick={() => setOddsFormat("fractional")}/>
      <label className="fw-light btn btn-outline-secondary btn-sm" htmlFor="odds-fractional">Fractional</label>
      &nbsp;
      <input type="radio" className="btn-check btn-sm" name="odds-format" id="odds-decimal" autoComplete="off" onClick={() => setOddsFormat("decimal")}/>
      <label className="fw-light btn btn-outline-secondary btn-sm" htmlFor="odds-decimal">Decimal</label>
      &nbsp;
      <input type="radio" className="btn-check btn-sm" name="odds-format" id="odds-american" autoComplete="off" onClick={() => setOddsFormat("american")}/>
      <label className="fw-light btn btn-outline-secondary btn-sm" htmlFor="odds-american">American</label>
      </div>
      <SearchBar keyword={keyword} onChange={updateKeyword} onKeyDown={handleKeyDown}/>
      {loading && <div></div>}
      {error && <div>{`${error}`}</div>}
      <Matches matches={matches} onClick={updateOdds}/>
      <Odds odds={odds} oddsFormat={oddsFormat}/>
      <br></br>
      <br></br>
    </div>
    </>
  )
}

export default MatchesWithSearch;

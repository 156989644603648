import Helmet from 'react-helmet';
import './App.css';
import MatchesWithSearch from './MatchesWithSearch';

function App() {
  return (
    <div className="App">
        <Helmet>
	    <script type="text/javascript">{`
		(function(c,l,a,r,i,t,y){
        		c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        		t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        		y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    		})(window, document, "clarity", "script", "ktebmxv1fn");`}
	    </script>
	</Helmet>
      	<MatchesWithSearch />
    </div>
  );
}

export default App;

const SearchBar = ({keyword, onChange, onKeyDown}) => {
    return (
      <div className="container p-3">
        <input autoFocus
          type="search"
          className="form-control w-100"
          key="search-bar"
          value={keyword}
          placeholder={"Type and click on a match to get odds"}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={(e) => onKeyDown(e)}
        />
      </div>
    );
  }
  
  export default SearchBar;
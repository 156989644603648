const Matches = ({matches = [], onClick}) => {
  return (    
    <div className="container">
      <div className="list-group">
      {matches &&
        matches.map(({ host, guest, matchDate, competition, matchID }) => (
          <button className='list-group-item list-group-item-action' 
            onClick= {(e) => onClick({matchID})}
            key={matchID}>
            <div className="table-responsive">
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <td className="text-center col-md-3">{competition}</td>
                    <td className="text-center col-md-3"><span className="text-danger">{host}</span> <span className="text-success">vs</span> <span className="text-primary">{guest}</span></td>
                    <td className="text-center col-md-6">{(new Date(matchDate)).toString()}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </button>                        
        ))}
      </div>
    </div>
  );
};
  
export default Matches;
const finaltouchAmerican = (oddsAmerican) => {
        if (oddsAmerican > 0){
                oddsAmerican = '+' + oddsAmerican;
        }
        return oddsAmerican;
}

const finaltouchFractional = (oddsDecimal, oddsFractional) => {
        if (oddsDecimal >= 11.0){
                oddsFractional = Math.round(oddsDecimal - 1.0) + '/1';
        }
        return oddsFractional;
}

const Odds = ({odds = {}, oddsFormat}) => {
        let oddsWin, oddsDraw, oddsLose;
        if (odds && odds.oddsWin){
                switch (oddsFormat){
                        case "prob":
                                oddsWin = (odds.oddsWin.to('impliedProbability')*100).toFixed(1)+'%';
                                oddsDraw = (odds.oddsDraw.to('impliedProbability')*100).toFixed(1)+'%';
                                oddsLose = (odds.oddsLose.to('impliedProbability')*100).toFixed(1)+'%';
                                break;
                        case "fractional":
                                oddsWin = finaltouchFractional(odds.oddsWin.to('decimal'), odds.oddsWin.to('fractional', {precision: 2}));
                                oddsDraw = finaltouchFractional(odds.oddsDraw.to('decimal'), odds.oddsDraw.to('fractional', {precision: 2}));
                                oddsLose = finaltouchFractional(odds.oddsLose.to('decimal'), odds.oddsLose.to('fractional', {precision: 2}));
                                break;
                        case "decimal":
                                oddsWin = odds.oddsWin.to('decimal').toFixed(2);
                                oddsDraw = odds.oddsDraw.to('decimal').toFixed(2);
                                oddsLose = odds.oddsLose.to('decimal').toFixed(2);
                                break;
                        case "american":
                                oddsWin = finaltouchAmerican(Math.round(odds.oddsWin.to('moneyline')));
                                oddsDraw = finaltouchAmerican(Math.round(odds.oddsDraw.to('moneyline')));
                                oddsLose = finaltouchAmerican(Math.round(odds.oddsLose.to('moneyline')));
                                break;
                        default:
                                break;
                }
        }
        return (
        <div className="container p-2">
            {odds && odds.probWin && 
                <div className="card card-body bg-light">
                <table className="table table-borderless">
                        <tbody>
                                <tr>
                                        <td className="text-center text-secondary fs-3 py-1" colSpan="3">{odds.competition}</td>
                                </tr>
                                <tr>
                                        <td className="text-center text-secondary fs-5 pb-3" colSpan="3">{(new Date(odds.matchDate)).toString()}</td>
                                </tr>
                                <tr>
                                        <th className="text-center col-md-4 text-danger fs-4 pt-3">{odds.host}</th>
                                        <th className="text-center col-md-4 text-success fs-4 pt-3">{odds.draw}</th>
                                        <th className="text-center col-md-4 text-primary fs-4 pt-3">{odds.guest}</th>
                                </tr>
                                <tr>
                                        <td className="text-center col-md-4 text-danger fs-2 pb-3">{oddsWin}</td>
                                        <td className="text-center col-md-4 text-success fs-2 pb-3">{oddsDraw}</td>
                                        <td className="text-center col-md-4 text-primary fs-2 pb-3">{oddsLose}</td>
                                </tr>
                                <tr></tr>
                                <tr>
                                        <td className="text-center text-muted fs-6 pt-3" colSpan="3">Odds were calculated at {(new Date(odds.lastUpdate)).toLocaleString()} for the full time result only (90 minutes plus stoppage time)</td>
                                </tr>
                        </tbody>
                </table>
                </div>
            }
        </div>
);
};

export default Odds;